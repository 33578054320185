import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    ROUTE_LANDING,
    ROUTE_REGISTRATION,
    ROUTE_SPIN_N_WIN,
} from "../helpers/Constants";
import { UserState } from "../redux/slices/userSlice";
import { withItems } from "../hoc/withItems";
const videoSources = [
    "Jumbo1.mp4",
    "Jumbo2.mp4",
    "Jumbo3.mp4",
    "Jumbo4.mp4",
    "Jumbo5.mp4",
    "Jumbo6.mp4",
    "Jumbo7.mp4",
    "Jumbo8.mp4",
    "Jumbo9.mp4",
    "Jumbo10.mp4",
];

const IntroPage = ({ user }: UserState) => {
    const index = Math.floor(Math.random() * videoSources.length);
    // const index = Math.round(Math.random() * (10 - 1) + 1);
    const videoFile = videoSources[index];
    const navigate = useNavigate();

    const handleClick = () => {
        if (!user?.is_registered) {
            navigate(ROUTE_REGISTRATION, { replace: true });
        } else if (user?.is_registered && user.can_spin_today === true) {
            navigate(ROUTE_SPIN_N_WIN, { replace: true });
        } else if (user?.is_registered && user.can_spin_today === false) {
            navigate(ROUTE_LANDING, { replace: true });
        }
    };

    useEffect(() => {
        // Detect if the browser is Safari
        if (
            navigator.userAgent.includes("Safari") &&
            !navigator.userAgent.includes("Chrome")
        ) {
            // Apply the background color to the element with the class "home-page-section"
            const element = document.querySelector(".home-page-section");
            if (element) {
                element.style.backgroundColor = "#EEB717";
            }
        }
    }, []);
    useEffect(() => {
        if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
            const itemName = document.querySelector(".home-page-section");
            if (itemName) {
                itemName.style.backgroundColor = "#f1be16";
            }
        }
    }, []);

    return (
        <section className="home-page-section">
            <div className="inner-section desktop-intro-page">
                <div className="heading-section text-center desk-secound-section">
                    <img
                        src="../front/assets/img/new-intro-head.png"
                        alt=""
                        className="img-fluid head-img"
                    />
                </div>
                <div className="first-colume">
                    <div className="animate-video-sec text-center">
                        <video
                            className="video-tag video-img  home-video"
                            width="100"
                            height="100"
                            loop={false}
                            controls={false}
                            autoPlay
                            muted={true}
                            playsInline
                            webkit-playsinline="true"
                        >
                            <source
                                src={`/front/assets/IntroVideos/${videoFile}`}
                                type="video/mp4"
                            />
                        </video>
                        {/* <img
                            videoFile="../front/assets/img/another-img9000.png"
                            alt=""
                            className="img-fluid video-img video-tag"
                        /> */}
                    </div>
                    <div className="btn-section">
                        {/* <Link to={ROUTE_REGISTRATION}> */}
                        <div className="jumbo-btn" onClick={handleClick}>
                            <span className="text-light">DRAAI &</span>WIN
                        </div>
                        {/* </Link> */}
                        <p className="btn-txt">
                            VANAF 11 september <br />
                            <span className="txt-small text-white">
                                SITE DECATHLON & WEBA
                            </span>
                        </p>
                    </div>
                </div>
                <div className="bottom-map-div main-bg mobile-view-hide">
                    <div className="bottom-txt-div">
                        <img
                            src="../front/assets/img/map-image.png"
                            alt=""
                            className="img-fluid map-image"
                        />
                        {/* <p className="txt-botm">MAKE IT, TAKE IT, EAT IT</p>  */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withItems(IntroPage);
