import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";
import {KeyValueContainer} from "@snayvik-tech-labs/guestcamp-api";

export interface Item {
    id: number
    title: string
    type: string
    meta: KeyValueContainer
    is_active: number
    created_at: string
    updated_at: string
    index: number
    subtitle: any
    active_status: boolean
}

export interface ItemsState {
    items?: Array<Item> | null | undefined;
    itemsLoading: boolean;
    error?: Error | null;
}

const initialState: ItemsState = {
    items: undefined,
    itemsLoading: false,
    error: null,
};
type ItemsResponseType = APIResponse<User>
export const fetchItems = createAsyncThunk<ItemsResponseType, User, { rejectValue: Error }>('items', async (args) => {
    const response = await axios.get<User>(`${API_URL}fetch-items?mail_recepient_id=${args.mail_recepient_id}`);
    return response.data;
});

const itemsSlice = createSlice<ItemsState>({
    name: 'items',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchItems.pending, (state) => {
                state.itemsLoading = true;
                state.error = null;
            })
            .addCase(fetchItems.fulfilled, (state, action: PayloadAction<ItemsResponseType>) => {
                state.itemsLoading = false;
                state.items = action.payload.data;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.itemsLoading = false;
                state.items = null;
                state.error = action.error?.message || 'Failed to fetch users';
            });
    },
});

export default itemsSlice.reducer;
