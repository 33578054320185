import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {GuestCampClient, KeyValueContainer, MailExist} from "@snayvik-tech-labs/guestcamp-api"

export interface EmailCheckState {
    user?: KeyValueContainer | null;
    emailCheckLoading: boolean;
    error?: Error | null;
}

const initialState: EmailCheckState = {
    user: null,
    emailCheckLoading: false,
    error: null,
};

export const checkEmail = createAsyncThunk<KeyValueContainer, string, { rejectValue: Error }>('email-check', async (email) => {
    const res = await GuestCampClient.GetUserDetails({ email} as KeyValueContainer);
    return res.data;
});

const userSlice = createSlice<EmailCheckState>({
    name: 'email-check',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkEmail.pending, (state) => {
                state.emailCheckLoading = true;
                state.error = null;
            })
            .addCase(checkEmail.fulfilled, (state, action: PayloadAction<KeyValueContainer>) => {
                state.emailCheckLoading = false;
                state.user = action.payload;
            })
            .addCase(checkEmail.rejected, (state, action) => {
                state.emailCheckLoading = false;
                state.error = action.error?.message || 'Failed to fetch email check';
            });
    },
});

export default userSlice.reducer;
