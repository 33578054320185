import React, {forwardRef, useEffect} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import {LocalStorage} from "../helpers/LocalStorage";
import {fetchSegments, resetWheelState, WheelState} from "../redux/slices/wheelSlice";
import {fetchUser} from "../redux/slices/userSlice";
import {ROUTE_LANDING, ROUTE_WEEK_DEAL} from "../helpers/Constants";
import {useNavigate} from "react-router-dom";


export function withWheel<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = forwardRef((props: P & WheelState, ref) => {
        const navigate = useNavigate();
        const dispatch = useAppDispatch();
        const wheelState = useSelector((state: RootState) => state.wheel);
        const userState = useSelector((state: RootState) => state.user);

        useEffect(() => {
            const user = LocalStorage.getUser()!;
            if (!userState.user && userState.userLoading === false){
                dispatch(fetchUser(user));
            }else {
                // if (!userState.user.can_spin_today){
                //     navigate(ROUTE_LANDING)
                // }else

                if (wheelState.segmentsLoading === false && wheelState.segments === undefined){
                    dispatch(fetchSegments(user))
                }
            }
        }, [dispatch, userState.user, wheelState.segments]);

        // useEffect(() => {
        //     if (userState.user){
        //     }
        // }, [dispatch, userState.user]);

        return <WrappedComponent {...props}
            ref={ref}
            {...wheelState}
        />
    })
    return UserControlled;
}
