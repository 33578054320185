import React, {useEffect} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../helpers/Constants";
import {SegmentData} from "../components/Wheel/Wheel";
import {APIResponse} from "../redux/models/APIResponse";
import {WinnerData} from "../redux/slices/wheelSlice";
import {LocalStorage} from "../helpers/LocalStorage";
import {fetchPrize, PrizeState} from "../redux/slices/prizeSlice";
import {PrizeAction} from "./withPize";

export interface MyPrizeAction {
}
export function withMyPrize<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = (props: P & PrizeState & PrizeAction) => {
        const dispatch = useAppDispatch();
        const prizeState = useSelector((state: RootState) => state.prize);

        useEffect(() => {
            const user = LocalStorage.getUser()!;
            if (prizeState.prizeLoading === false && prizeState.prize === undefined){
                dispatch(fetchPrize(user))
            }
        }, [])

        return <WrappedComponent {...props}
            {...prizeState}
        />
    }
    return UserControlled;
}
