import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
    Field,
    KeyValueContainer,
    GuestCampClient,
    Registration,
    StringKeyValueContainer,
} from "@snayvik-tech-labs/guestcamp-api";
import { LoadGif } from "../Spinner/Spinner";
import { Spinner } from "../Spinner/Spinner";
import axios from "axios";
import { API_URL } from "../../helpers/Constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

type RegistrationSuccessBlock = (
    registration: Registration | undefined
) => void;
type RegistrationFailBlock = (error: Error | undefined) => void;

interface RegistrationState {
    locale: string;
    email: string;

    onFieldRequestSuccess?: (data: Array<Field> | undefined) => void;
    onFieldRequestFail?: (error: Error | undefined) => void;

    onRegistrationSuccess?: RegistrationSuccessBlock;
    onRegistrationFail?: RegistrationFailBlock;
    onRegistrationRequestStart?: () => void;
    onFieldsLoading?: () => void;
    onFieldsLoaded?: () => void;
}
export const RegistrationUI = ({
    locale,
    email,
    onRegistrationFail,
    onRegistrationRequestStart,
    onRegistrationSuccess,
    onFieldsLoading,
    onFieldsLoaded,
    onFieldRequestFail,
    onFieldRequestSuccess,
}: RegistrationState) => {
    const [fields, setFields] = useState<Array<Field> | null>(null);
    const [registrationData, setRegistrationData] = useState<KeyValueContainer>(
        { email: email }
    );
    const [errorContainer, setErrorContainer] = useState<KeyValueContainer>({});
    const [privacyPolicyModel, setShowPrivacyPolicyModal] =
        useState<Boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fieldLoad, setFieldLoad] = useState(true);

    useEffect(() => {
        onFieldsLoading && onFieldsLoading();
        GuestCampClient.GetFields()
            .then((res) => {
                onFieldsLoaded && onFieldsLoaded();
                onFieldRequestSuccess && onFieldRequestSuccess(res);
                return res;
            })
            .then(setFields)
            .catch((error) => {
                onFieldRequestFail && onFieldRequestFail(error);
            });
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value, type } = e.target;
        setErrorContainer((pre) => {
            let data = { ...pre };
            pre[name as string] = null;
            return pre;
        });
        setRegistrationData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? e.target.checked : value,
        }));
    };

    const handleSubmit = (e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
        e.preventDefault();
        if (isLoading) return;

        onRegistrationRequestStart && onRegistrationRequestStart();
        setIsLoading(true);
        let requestPayload = {
            locale,
            fields: registrationData,
        } as KeyValueContainer;
        if (registrationData.email) {
            requestPayload.email = registrationData["email"];
        }
        GuestCampClient.Register(requestPayload)
            .then((res) => {
                setIsLoading(false);
                return res;
            })
            .then((res) => res.data)
            .then((registration) => {
                setIsLoading(true);
                const payload = { email: registration?.email };
                axios
                    .post(`${API_URL}user/detail`, payload)
                    .then((response) => response.data)
                    .then((data) => {
                        onRegistrationSuccess &&
                            onRegistrationSuccess(
                                data.data as KeyValueContainer
                            );
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        onRegistrationFail && onRegistrationFail(error);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                onRegistrationFail && onRegistrationFail(error);
                const errors = error.response?.data.errors;
                if (errors) {
                    const container: StringKeyValueContainer = {};
                    for (const key in errors) {
                        const _key = key.replace("fields.", "");
                        if (errors[key].length > 0) {
                            container[_key] = errors[key][0];
                        }
                    }
                    setErrorContainer(container);
                }
            });
    };
    // =================================date-text===============================
    // useEffect(() => {
    //     const isSafari = /^((?!chrome|android).)*safari/i.test(
    //         navigator.userAgent
    //     );
    //     if (isSafari) {
    //         const dateInputs = document.querySelectorAll('input[type="date"]');
    //         dateInputs.forEach((input) => {
    //             input.style.textAlign = "left";
    //         });
    //     }
    // }, []);
    // ================================================================

    return (
        <>
            <form
                className="form-tag form-section full-reg-form"
                onSubmit={handleSubmit}
                autoComplete="off"
                encType="multipart/form-data"
            >
                {fields?.map((field) => (
                    <div className="form-div" key={`field_${field.id}`}>
                        {field.field_type === "checkbox" && (
                            <div className="form-check">
                                <input
                                    type="checkbox"
                                    name={field.slug as string}
                                    defaultChecked={false}
                                    onChange={handleChange}
                                    required={field?.required ?? false}
                                    className="form-check-input check-width"
                                />
                                <label className="form-check-label text-light check-txt">
                                    {field.name && field.name[locale]}&nbsp;
                                    {field.slug === "agree_privacy_policy" && (
                                        <a
                                            href="#"
                                            style={{
                                                textDecoration: "underline",
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowPrivacyPolicyModal(true);
                                            }}
                                            className="txt-primary"
                                        >
                                            Jumbo Foodmarkt - Gent
                                            {field.required ? <sup>*</sup> : ""}
                                        </a>
                                    )}
                                </label>

                                {field.slug && errorContainer[field.slug]}
                            </div>
                        )}
                        {field.field_type === "email" && (
                            <div className="form-group m-btm text-light">
                                <label className="cl-lb">
                                    {field.name &&
                                        (field.name[locale] as string)}
                                    {field.required ? <sup>*</sup> : ""}
                                </label>

                                <input
                                    type={field.field_type}
                                    name={field.slug as string}
                                    onChange={handleChange}
                                    required={field?.required ?? false}
                                    value={email}
                                    className="form-control email input-field"
                                />
                                {field.slug &&
                                    (errorContainer[field.slug] as string)}
                            </div>
                        )}

                        {["text", "number", "date"].includes(
                            field.field_type ?? ""
                        ) && (
                            <div className="form-group m-btm text-light">
                                <label className="cl-lb">
                                    {field.name &&
                                        (field.name[locale] as string)}
                                    {field.required ? <sup>*</sup> : ""}
                                </label>

                                <input
                                    type={field.field_type}
                                    name={field.slug as string}
                                    onChange={handleChange}
                                    required={field?.required ?? false}
                                    // value={field.field_type === "email" && email }
                                    className="form-control email input-field"
                                />
                                {field.slug &&
                                    (errorContainer[field.slug] as string)}
                            </div>
                        )}
                    </div>
                ))}
                <div className="from-btn-div  text-center">
                    <button className="start-btn text-black email-reg">
                        Klaar om te draaien
                        {isLoading && <Spinner />}
                    </button>
                </div>
            </form>
            <Modal
                centered
                show={privacyPolicyModel}
                onHide={() => setShowPrivacyPolicyModal(false)}
            >
                <Modal.Body>
                    <div>
                        <p>
                            Als verwerkingsverantwoordelijke mag Jumbo Foodmarkt
                            mijn persoonsgegevens (de gegevens in dit formulier
                            en de context waarin ze werden verzameld) opslaan in
                            zijn bestanden met de bedoeling de communicatie te
                            personaliseren op basis van mijn interesses (die
                            worden afgeleid uit de evenementen en de wedstrijden
                            waaraan ik heb deelgenomen) en telefonisch en via
                            digitale kanalen (e-mail en/of sms) contact met me
                            opnemen. Ik weet dat ik het recht heb om mijn
                            toestemming op elk moment in te trekken en dat mijn
                            gegevens worden verwerkt in overeenstemming met het
                            privacybeleid van Jumbo, dat mij informeert over hoe
                            ik mijn privacyinstellingen kan aanpassen en hoe ik
                            mijn toestemming kan intrekken.
                        </p>{" "}
                        <br />
                        <p>
                            Indien ik geen toestemming geef worden mijn gegevens
                            niet langer dan 3 maanden bewaard en uitsluitend
                            gebruikt om mijn kortingen/promo opnieuw naar mij te
                            versturen indien er iets misgelopen is bij de eerste
                            verzending.
                        </p>
                    </div>
                    <div className="text-center mt-3">
                        <a
                            style={{ textDecoration: "underline" }}
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowPrivacyPolicyModal(false);
                            }}
                            className="txt-primary"
                        >
                            Sluiten
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
