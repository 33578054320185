import {User} from "../redux/models/User";
import {ROUTE_FULL_REGISTRATION, ROUTE_REGISTRATION, ROUTE_ROOT_PAGE, ROUTE_WEEK_DEAL} from "./Constants";

export class Utils {
    static getFieldName( name,locale ) {
        if(!name){
            return ""
        }
        if (typeof name === "string") {
            return name;
        }
        return name[locale] ?? "";
    }

    static isValidEmail(email: string){
        // const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const validRegex =/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const matches =  email.match(validRegex);
        return matches ? true : false;
    }
}
