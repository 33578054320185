import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";

export interface FunDeal {
    id: number
    title: string
    header_image: string
    prize_image: number
    is_active: number
    start_date: string
    end_date: string
}


export interface FunDealState {
    fanDeal?: Array<FunDeal> | null | undefined;
    fanDealLoading: boolean;
    error?: Error | null;
}

const initialState: FunDealState = {
    fanDeal: undefined,
    fanDealLoading: false,
    error: null,
};
type FunDealsResponseType = APIResponse<Array<FunDeal>>
export const fetchFunDeals = createAsyncThunk<FunDealsResponseType, User, { rejectValue: Error }>('funDeals', async (args) => {
    const response = await axios.get<User>(`${API_URL}fetch-fan-deal`);
    return response.data;
});

const funDealSlice = createSlice<FunDealState>({
    name: 'sundayOffers',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFunDeals.pending, (state) => {
                state.fanDealLoading = true;
                state.error = null;
            })
            .addCase(fetchFunDeals.fulfilled, (state, action: PayloadAction<FunDealsResponseType>) => {
                state.fanDealLoading = false;
                state.fanDeal = action.payload.data;
            })
            .addCase(fetchFunDeals.rejected, (state, action) => {
                state.fanDealLoading = false;
                state.fanDeal = null;
                state.error = action.error?.message || 'Failed to fetch offers';
            });
    },
});

export default funDealSlice.reducer;
