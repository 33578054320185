import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";

export interface SundayOffer {
    id: number
    title: string
    image: string
    image_index: number
    is_active: number
    created_at: string
    updated_at: string
    link: string
}


export interface SundayOfferState {
    offers?: Array<SundayOffer> | null | undefined;
    offersLoading: boolean;
    error?: Error | null;
}

const initialState: SundayOfferState = {
    offers: undefined,
    offersLoading: false,
    error: null,
};
type SundayOffersResponseType = APIResponse<Array<SundayOffer>>
export const fetchSundayOffers = createAsyncThunk<SundayOffersResponseType, User, { rejectValue: Error }>('sundayOffers', async (args) => {
    const response = await axios.get<User>(`${API_URL}fetch-crazy-sunday?mail_recepient_id=${args.mail_recepient_id}`);
    return response.data;
});

const sundayOffersSlice = createSlice<SundayOfferState>({
    name: 'sundayOffers',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSundayOffers.pending, (state) => {
                state.offersLoading = true;
                state.error = null;
            })
            .addCase(fetchSundayOffers.fulfilled, (state, action: PayloadAction<SundayOffersResponseType>) => {
                state.offersLoading = false;
                state.offers = action.payload.data;
            })
            .addCase(fetchSundayOffers.rejected, (state, action) => {
                state.offersLoading = false;
                state.offers = null;
                state.error = action.error?.message || 'Failed to fetch offers';
            });
    },
});

export default sundayOffersSlice.reducer;
