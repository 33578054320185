import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/userSlice';
import emailCheckReducer from '../slices/emailSlice';
import wheelReducer from '../slices/wheelSlice';
import itemsReducer from '../slices/itemsSlice';
import prizeReducer from '../slices/prizeSlice';
import sundayOffersReducer from '../slices/sundayOfferSlice';
import fanDealReducer from '../slices/fanDealSclice';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const store = configureStore({
    reducer: {
        user: userReducer,
        mailCheck: emailCheckReducer,
        wheel: wheelReducer,
        items: itemsReducer,
        prize: prizeReducer,
        sundayOffers: sundayOffersReducer,
        fanDealSclice: fanDealReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
type AppDispatchFunc = () => AppDispatch
export const useAppDispatch: AppDispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

