import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import WeekDealQrCodeImage from "../components/WeekDeal/WeekDealQrCodeImage";
import { withSpinDiscount } from "../hoc/withSpinDiscount";
import { API_URL, ROUTE_LANDING } from "../helpers/Constants";
import { withMyPrize } from "../hoc/withMyPrize";
import { Spinner } from "../components/Spinner/Spinner";
import WeekDealExchanged from "../components/WeekDeal/WeekDealExchanged";
import QrCodeDownload, {
    QrCodeDownloadRef,
} from "../components/QrCodeTemplate/QrCodeDownload";
import { FunDealState } from "../redux/slices/fanDealSclice";
import { withFanDeal } from "../hoc/withFanDeal";
import { LocalStorage } from "../helpers/LocalStorage";
import axios from "axios";
import CountDown from "../components/Landing/CountDown";
import { Modal, ModalBody } from "react-bootstrap";

enum DealUIState {
    LOCK,
    QR,
    EXCHANGE,
}

// const FunDealPage = ({ fanDealLoading, fanDeal }: FunDealState) => {
const FunDealPage = () => {
    const [fanDeal, setFanDeal] = useState();
    const [unlockFanDeal, setUnlockFanDeal] = useState();
    const [fanDealLoading, setFanDealLoading] = useState(true);
    const [loading, setLoading] = useState<Boolean | null>();
    const [type, setType] = useState<DealUIState>(DealUIState.QR);
    const downloadRef = useRef<QrCodeDownloadRef | null>(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const [qr, setQr] = useState(null);

    const user = LocalStorage.getUser();
    const handleUnlockClick = () => {
        // setType(DealUIState.QR);
        setLoading(true);

        const payload = {
            mail_recepient_id: user?.mail_recepient_id,
            fan_deal_id: fanDeal?.id,
        };
        axios
            .post(`${API_URL}unlock-fan-deal`, payload)
            .then((data) => {
                setUnlockFanDeal(data?.data?.data);
                setQr(data?.data?.data.qr_image);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    // const handleQrCodeImageClick = () => {
    //     setShowExchangeImage(true);
    //     setQrCodeImage(false)
    // };

    const handleDownloadQRCode = useCallback(() => {
        downloadRef?.current?.download();
    }, [unlockFanDeal]);

    useEffect(() => {
        const user = LocalStorage.getUser();
        setFanDealLoading(true);
        axios
            .get(
                `${API_URL}fetch-fan-deal?mail_recepient_id=${user?.mail_recepient_id}`
            )
            .then((data) => {
                setFanDeal(data?.data?.data);
                setQr(data?.data?.data.qr_image);
                setFanDealLoading(false);
            });
    }, []);

    return (
        <section className="dit-main-section home-page-section">
            <div className="inner-section deal-inner-sec">
                <div className="back-sec">
                    <Link to={ROUTE_LANDING}>
                        {" "}
                        <img
                            src="../front/assets/img/black-back.png"
                            alt=""
                            className="black-back-img deal-back-img"
                        />
                    </Link>
                </div>
                <div className="inner-section-deal">
                    {fanDealLoading ? (
                        <div className="text-center m-5">
                            <Spinner color={"#000"} />
                        </div>
                    ) : (
                        <div className="deal-sec">
                            <div
                                className="deal-card-img"
                                style={{
                                    background:
                                        "url(" + fanDeal?.header_image + ")",
                                }}
                            >
                                {type === DealUIState.QR && fanDeal && (
                                    <>
                                        {unlockFanDeal?.qr_image ||
                                        fanDeal?.qr_image ? (
                                            <div className="qr-dwn-div position-relative">
                                                <img
                                                    alt=""
                                                    className="img-fluid deal-qr-dwn"
                                                    src={qr}
                                                    // src="../front/assets/img/demo-qr-img.png"
                                                />
                                                <div>
                                                    <div className="dwn-div">
                                                        <img
                                                            // src="../front/assets/img/dwn-mobile-image.png"
                                                            alt=""
                                                            className="dwn-img"
                                                            onClick={()=>{
                                                                handleDownloadQRCode();
                                                                if (fanDeal && fanDeal?.count_down_time){
                                                                    setType(DealUIState.EXCHANGE);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    {/* {qr && ( */}
                                                        {/* <QrCodeDownload */}
                                                            {/* ref={downloadRef} */}
                                                            {/* title="WEEK-DEAL" */}
                                                            {/* qr_image={qr} */}
                                                        {/* /> */}
                                                    {/* )} */}
                                                    <div className="week-dwn-content desktop-view-hide">
                                                        <ul className="food-mr-ul text-center p-0">
                                                            <li className="li-fd-mr">
                                                                1. Ga naar de
                                                                Foodmarkt Gent
                                                            </li>
                                                            <li className="li-fd-mr">
                                                                2. wissel je
                                                                code in aan de
                                                                ingang
                                                            </li>
                                                            <li className="li-fd-mr txt-primary">
                                                                3. geniet je Fan
                                                                Deal
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="new-tab-div fan-new-tab">
                                                        <a
                                                            href="#"
                                                            className="new-page-tab text-black"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowSuccessPopup(true);
                                                            }}
                                                        >
                                                            <img src="../front/assets/img/orange-i.png" alt="" className="modal-i-circle img-fluid"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                )}
                                {type === DealUIState.EXCHANGE && fanDeal && (
                                    fanDeal?.count_down_time  ? (
                                        <div className="deal-exchang-card">
                                            <div className="deal-ex-content">
                                                <p className="dealEx-txt">
                                                Hi {user?.first_name}, <br /> Je deal werd ingewisseld.
                                                </p>
                                                <p className="dealEx-txt ">
                                                Klaar voor de volgende{" "}
                                                <span className="txt-primary">Fan Deal</span>?
                                                </p>
                                            </div>
                                            <div className="deal-exhange-jumboImg">
                                                <img
                                                src="../front/assets/img/Jumbo_Foodmarkt_Logo_Zwart.svg"
                                                alt=""
                                                className="wek-jum-img img-fluid"
                                                />
                                            </div>
                                            <div className="dea-timer-div">
                                                <div className="dealEx-txt">
                                                <CountDown targetDate={fanDeal?.count_down_time} />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="qr-dwn-div">
                                            <img
                                            alt=""
                                            className="img-fluid deal-qr-dwn"
                                            src={qr}
                                            />
                                            {/* <div>
                                                <div className="dwn-div">
                                                <img
                                                    // src="../front/assets/img/dwn-mobile-image.png"
                                                    src={qr}
                                                    alt=""
                                                    className="dwn-img"
                                                    onClick={handleDownloadQRCode}
                                                />
                                                </div>
                                                {qr && (
                                                <QrCodeDownload
                                                    ref={downloadRef}
                                                    title="WEEK-DEAL"
                                                    qr_image={qr}
                                                />
                                                )}
                                            </div> */}
                                            <div className="week-dwn-content desktop-view-hide">
                                                <ul className="food-mr-ul text-center p-0">
                                                    <li className="li-fd-mr">
                                                        1. Ga naar de
                                                        Foodmarkt Gent
                                                    </li>
                                                    <li className="li-fd-mr">
                                                        2. wissel je
                                                        code in aan de
                                                        ingang
                                                    </li>
                                                    <li className="li-fd-mr txt-primary">
                                                        3. geniet je Fan
                                                        Deal
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="new-tab-div fan-new-tab">
                                                <a
                                                    href="#"
                                                    className="new-page-tab text-black"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowSuccessPopup(true);
                                                    }}
                                                >
                                                    <img src="../front/assets/img/orange-i.png" alt="" className="modal-i-circle img-fluid"/>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            {/* {type === DealUIState.LOCK && ( */}
                            {!unlockFanDeal?.qr_image &&
                            fanDeal?.already_unlock === false ? (
                                <div className="unlock-section">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        <img
                                            src="../front/assets/img/lock-img.png"
                                            alt=""
                                            className="lock-img"
                                            onClick={handleUnlockClick}
                                        />
                                    )}
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="ls-deal-card-sec">
                                <div className="deal-itme-sec">
                                    {/* {fanDeal?.prize_image && ( */}
                                    <img
                                        src={fanDeal?.price_image}
                                        // src="../front/assets/img/item.png"
                                        alt=""
                                        className="img-fluid itme-img"
                                    />
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="week-bottom-div">
                    <div className="week-btm-img">
                        <img
                            src="../front/assets/img/Group-487.svg"
                            alt=""
                            className="img-fluid jum-week-img"
                        />
                        <img
                            src="../front/assets/img/Group-485.svg"
                            alt=""
                            className="img-fluid food-week-img"
                        />
                    </div>
                    <p className="week-btm-txt">SITE DECATHLON & WEBA</p>
                    <div className="buttom-new-tab">
                        <a
                            href="../assets/Wedstrijdreglement_JUMBO_Draai_Win_Foodmarkt_Gent.pdf"
                            className="new-page-tab text-black"
                            target="_blank"
                        >
                            <img src="../front/assets/img/white-i.png" alt="" className="modal-i-circle img-fluid"/>
                        </a>
                    </div>
                </div>
            </div>
            <Modal
                show={showSuccessPopup}
                centered
                size="lg"
                scrollable
                backdrop="static"
                className="road-reg-pop"
            >
                <ModalBody className="reg-popup-body">
                <div className="container">
                    <h2 className="full-reg-pop-head">
                        {fanDeal?.desc}
                    </h2>
                    <button
                    className="slut-reg-pop"
                    onClick={() => {
                        setShowSuccessPopup(false);
                    }}
                    >
                    SLUITEN
                    </button>
                </div>
                </ModalBody>
            </Modal>
        </section>
    );
};

// export default withFanDeal(FunDealPage);
export default FunDealPage;
