import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";

export interface Prize {
    qr_image?: string;
    prize_id?: number;
    image?: string;
    title?: string;
  }


export interface PrizeState {
    prize?: Prize | null | undefined;
    prizeLoading: boolean;
    error?: Error | null;
}

const initialState: PrizeState = {
    prize: undefined,
    prizeLoading: false,
    error: null,
};
type PrizeResponseType = APIResponse<Prize>
export const fetchPrize = createAsyncThunk<PrizeResponseType, User, { rejectValue: Error }>('prize', async (args) => {
    const response = await axios.get<User>(`${API_URL}fetch-prize?mail_recepient_id=${args.mail_recepient_id}`);
    return response.data;
});

const prizeSlice = createSlice<PrizeState>({
    name: 'prize',
    initialState,
    reducers: {
        nop: (state) => state,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrize.pending, (state) => {
                state.prizeLoading = true;
                state.error = null;
            })
            .addCase(fetchPrize.fulfilled, (state, action: PayloadAction<PrizeResponseType>) => {
                state.prizeLoading = false;
                state.prize = action.payload.data;
            })
            .addCase(fetchPrize.rejected, (state, action) => {
                state.prizeLoading = false;
                state.prize = null;
                state.error = action.error?.message || 'Failed to fetch users';
            });
    },
});

export default prizeSlice.reducer;
