import React, { useState, useEffect } from "react";

const TextChanger = () => {
    const texts = ["De Beste Producten", "Lage prijzen", "Gezellig winkelen!"];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [text, setText] = useState(texts[0]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((currentIndex + 1) % texts.length);
            setText(texts[currentIndex]);
        }, 1000); // change text every 1 seconds

        return () => clearInterval(intervalId);
    }, [texts, currentIndex]);

    return <h2 className="head2">{text}</h2>;
};

export default TextChanger;
