import React, {useEffect} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../helpers/Constants";
import {SegmentData} from "../components/Wheel/Wheel";
import {APIResponse} from "../redux/models/APIResponse";
import {WinnerData} from "../redux/slices/wheelSlice";
import {fetchItems} from "../redux/slices/itemsSlice";
import {LocalStorage} from "../helpers/LocalStorage";

export interface ItemsAction {
}
export function withItems<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = (props: P & ItemsAction) => {
        const dispatch = useAppDispatch();
        const userState = useSelector((state: RootState) => state.user);
        const itemsState = useSelector((state: RootState) => state.items);

        useEffect(() => {
            if (itemsState.itemsLoading === false && userState.user){
                const user = LocalStorage.getUser()!;
                dispatch(fetchItems(user))
            }
        }, [userState.user])


        // const fetchBarcodePrize = async (): Promise<SegmentData> => {
        //     // Replace with actual API call
        //     return new Promise((resolve, reject) => {
        //         setTimeout(async () => {
        //             const winningPrize = await axios
        //                 .get<WinnerData>(`${API_URL}spin?mail_recepient_id=${userState.user.mail_recepient_id}`)
        //                 .then(res => {
        //                     return res.data.data;
        //                 })
        //                 .catch((e) => {
        //                     console.error(e)
        //                     reject(e)
        //                 });
        //             resolve({ winningPrize });
        //         }, 2000);
        //     });
        // };

        return <WrappedComponent {...props}
            {...itemsState}
            {...userState}
        />
    }
    return UserControlled;
}
