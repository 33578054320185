import React from "react";

interface SegmentProps {
    name: string;
    backgroundColor: string;
    rotate: number;
    textColor?: string;
}

const Segment: React.FC<SegmentProps> = ({
    name,
    backgroundColor,
    rotate,
    textColor,
}) => {
    //   const textColor = rotate % 2 === 0 ? "black" : "white";
    return (
        <div
            className="slice"
            key={name}
            style={{
                transform: `rotate(${rotate}deg)`,
                backgroundColor,
            }}
        >
            <span
                className="spin-text"
                style={{
                    color: textColor,
                }}
            >
                {" "}
                {name}
            </span>
            <div className="led-yellow"></div>
        </div>
    );
};

export default Segment;
