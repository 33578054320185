import React, { useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import TextChanger from "../components/MobilePractice/TextChanging";
import { ROUTE_LANDING } from "../helpers/Constants";
import Modal from "react-bootstrap/Modal";
import { Item } from "../redux/slices/itemsSlice";
import { KeyValueContainer } from "@snayvik-tech-labs/guestcamp-api";
import { Spinner } from "react-bootstrap";

const MobilePracticePage = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    // const [VideoLoading, setVideoLoading] = useState(false);
    const videoRef = useRef(null);
    const location = useLocation();
    const { meta }: { meta: KeyValueContainer } = location.state;

    const [showPopup, setShowPopup] = useState(false);
    const handleThumbnailClick = () => {
        setIsPlaying(true);
        videoRef.current.play();
    };

    const handleMeerInfoClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setIsPlaying(false);
        setShowPopup(false);
    };
    let items = [];
    if (meta) {
        Object.keys(meta).forEach((key) => {
            items.push(meta[key]);
        });
    }
    return (
        <section className="home-page-section bg-change mobile-practical-section">
            <div className="inner-section padd-top">
                <div className="container">
                    <div className="back-btn-sec">
                        <Link to={ROUTE_LANDING}>
                            <img
                                src="../front/assets/img/back-btn.png"
                                alt=""
                                className="back-btn"
                            />
                        </Link>
                    </div>
                    <div className="deatil-heading-sec">
                        <div className="top-head">
                            <h2 className="detl-head">
                                FOODMARKT{" "}
                                <span className="txt-primary">WA ES DA?</span>
                            </h2>
                        </div>
                        <div className="second-head">
                            <TextChanger />
                        </div>

                        <div className="opening-content-sec">
                            <div className="left-side">
                                <div className="location-rout-div text-center">
                                    <img
                                        src="../front/assets/img/location-name.png"
                                        alt=""
                                        className="location-img"
                                    />
                                    <Link
                                        to={
                                            "https://maps.app.goo.gl/NmTjoAKHR9NNQrUh6"
                                        }
                                    >
                                        <span className="route-nm">route </span>
                                    </Link>
                                </div>
                                <div className="open-time-div">
                                    <p className="open-time-title">
                                        <img
                                            src="../front/assets/img/clock.png"
                                            alt=""
                                            className="clock"
                                        />
                                        OPENINGSUREN
                                    </p>
                                </div>
                                <div className="timeing-div">
                                    <ul className="time-ul">
                                        {items.map((item, index) => (
                                            <li
                                                key={index}
                                                className="opne-time-li"
                                            >
                                                <p className="open-content">
                                                    {item.day}
                                                </p>
                                                <p className="time">
                                                    {item.timeRange}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="opening-date-sec">
                                <h2 className="open-date">
                                    VANAF 11 SEPTEMBER
                                    <br />
                                    Site Decathlon & WEBA
                                </h2>
                                <div
                                    className="info-div"
                                    onClick={handleMeerInfoClick}
                                >
                                    <span className="info-btn">MEER INFO</span>
                                </div>
                            </div>
                            <div className="bottom-text-sec mobile-buttom">
                                <div className="head-div d-flex justify-content-center align-items-center">
                                    <div className="butm-jumb-img">
                                        <img
                                            className="jumbo-img-btm img-fluid"
                                            src="../front/assets/img/Group-487.svg"
                                            alt=""
                                        />
                                    </div>
                                    <div className="butm-jumb-img food-mrkt-img">
                                        <img
                                            className="food-mrkt-img img-fluid"
                                            src="../front/assets/img/for-black-food.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="buttom-new-tab">
                                    <a
                                        href="../assets/Wedstrijdreglement_JUMBO_Draai_Win_Foodmarkt_Gent.pdf"
                                        className="new-page-tab text-black"
                                        target="_blank"
                                    >
                                        <img src="../front/assets/img/white-i.png" alt="" className="modal-i-circle img-fluid"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showPopup}
                onHide={() => handleClosePopup()}
                centered
                size="lg"
                scrollable
                className="mobile-practical-modal"
            >
                <Modal.Body className="mb-modal-body">
                    <div className="form-response-model">
                        <div className="">
                            <div className="congrates-sec position-relative">
                                <div className="modal-head-div desktop-view-hide">
                                    <h2 className="head-modal">
                                        Foodmarkt Gent grote opening
                                    </h2>
                                </div>
                                <div className="middle-div-modal">
                                    <div className="modal-item-list">
                                        <ul className="md-ul">
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    RANDANIMATIE
                                                </p>
                                            </li>
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    GRATIS BLOEMETJE
                                                </p>
                                            </li>
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    WIN ACTIES
                                                </p>
                                            </li>
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    PROEVERIJEN
                                                </p>
                                            </li>
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    RANDANIMATIE
                                                </p>
                                            </li>
                                            <li className="list-modal-item">
                                                <span className="right">
                                                    <img
                                                        src="../front/assets/img/right.png"
                                                        alt=""
                                                        className="img-fluid right"
                                                    />
                                                </span>
                                                <p className="modal-item-content">
                                                    KINDERGRIME
                                                </p>
                                            </li>
                                            <li className="text-center size-32">
                                                ...
                                            </li>
                                        </ul>
                                        <div
                                            className="sluit-btn-div"
                                            onClick={handleClosePopup}
                                        >
                                            <button className="btn-sluit">
                                                SLUIT
                                            </button>
                                        </div>
                                    </div>
                                    <div className="modal-video-sec">
                                        <div className="modal-head-div mobile-view-hide">
                                            <h2 className="head-modal">
                                                Foodmarkt Gent grote opening
                                            </h2>
                                        </div>
                                        <div className="info-video-div">
                                            {/* {VideoLoading && <Spinner />} */}
                                            <video
                                                poster="/front/assets/img/poster-img.png"
                                                className="img-fluid modal-video position-relative"
                                                ref={videoRef}
                                                // onLoadStart={() =>
                                                //     setVideoLoading(true)
                                                // }
                                                // onCanPlay={() =>
                                                //     setVideoLoading(false)
                                                // }
                                                // onEnded={() => {
                                                //     setVideoLoading(false),
                                                //         setIsPlaying(false);
                                                // }}
                                                // loop
                                                // muted
                                                // controls
                                                // autoPlay
                                                playsInline
                                                webkit-playsinline="true"
                                            >
                                                <source
                                                    src="/front/assets/img/video6.mp4"
                                                    type="video/mp4"
                                                />
                                            </video>

                                            {!isPlaying && (
                                                <img
                                                    src="../front/assets/img/play-btn.png"
                                                    alt=""
                                                    className="img-fluid play-btn"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={
                                                        handleThumbnailClick
                                                    }
                                                />
                                            )}
                                        </div>
                                        {/* <img
                                            className="img-fluid modal-video"
                                            src="../front/assets/img/video.png"
                                            alt=""
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default MobilePracticePage;
