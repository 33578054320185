import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    forwardRef,
    useImperativeHandle,
    ComponentRef,
} from "react";
import Segment from "./Segment";
import SpinCircle from "./SpinCircle";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { WheelStateModel, withWheel } from "../../hoc/withWheel";
import { WheelState, WinnerData } from "../../redux/slices/wheelSlice";
import { PrizeAction, withPrize } from "../../hoc/withPize";
import { Spinner } from "../Spinner/Spinner";
import QrCodeDownload from "../QrCodeTemplate/QrCodeDownload";
import { Modal, ModalBody } from "react-bootstrap";

export interface ApiResponse {
    winningPrize: string;
}

export interface SegmentData {
    title: string;
    substitle: string;
}

export type WheelType = {
    spinClick: () => void;
};
type ChildProps = WheelState & WheelStateModel & PrizeAction & WheelState;
export type WheelRef = ComponentRef<typeof Wheel>;

const Wheel = React.forwardRef<WheelType, ChildProps>(
    (
        {
            segments,
            segmentsLoading,
            user,
            spinAPI,
            handlePrize,
            winner,
            spinning,
            spinError,
            setShowModal,
            showModal,
        }: WheelState & WheelStateModel & PrizeAction & WheelState,
        ref
    ) => {
        // const [totalQuantityWon, setTotalQuantityWon] = useState<number>(0);
        const [spinDegrees, setSpinDegrees] = useState<number>(0);
        const [result, setResult] = useState<string>("");
        const intervalRef = useRef<NodeJS.Timeout | null>(null);
        const slowDownIntervalRef = useRef<NodeJS.Timeout | null>(null);
        const [winningSegment, setWinningSegment] = useState<
            number | undefined
        >(undefined);

        /**
         * Expose the `onClick` function to the parent component.
         */
        useImperativeHandle(ref, () => ({
            spinClick() {
                spinWheel();
            },
        }));

        const audioRef = useRef<HTMLAudioElement | null>(null);

        const getSegmentByName = useCallback(
            (name: string): SegmentData => {
                return (segments &&
                    segments.find(
                        (segment: SegmentData) => segment.title === name
                    )) as SegmentData;
            },
            [segments]
        );

        // useEffect(() => {
        //     if (audioRef.current) {
        //       audioRef.current.load(); // Load the audio element
        //     }
        // }, []);

        useEffect(() => {
            if (audioRef.current) {
              audioRef.current.load(); // Load the audio element
            }
        }, []);



        useEffect(() => {
            const interval = 20; // 20 ms interval for smoother animation
            const spinDuration = 4000; // 8 seconds spin duration
            let currentDegrees = spinDegrees;

            if (spinning || spinError || winner) {
                if (spinning && audioRef.current) {
                    audioRef.current.play();
                }
                const startTime = performance.now();

                intervalRef.current = setInterval(() => {
                    const elapsed = performance.now() - startTime;

                    if (elapsed >= spinDuration) {
                        clearInterval(intervalRef.current!);

                        if (spinError || winner === null) {
                            // Handle the case when there's a spin error or no winner
                            const degreesPerSegment = 360 / segments.length;
                            const stopDegrees = degreesPerSegment / 2;
                            let remainingDegrees = stopDegrees - currentDegrees;
                            if (remainingDegrees < 0) remainingDegrees += 360;

                            slowDownIntervalRef.current = setInterval(() => {
                                if (Math.round(remainingDegrees) <= 0) {
                                    clearInterval(slowDownIntervalRef.current!);
                                    toast.error(
                                        "No items available for this hour"
                                    );
                                    // Stop spinner sound
                                    if (audioRef.current) {
                                        audioRef.current.pause();
                                        audioRef.current.currentTime = 0;
                                    }
                                    handlePrize();
                                } else {
                                    currentDegrees =
                                        (currentDegrees +
                                            remainingDegrees / 20) %
                                        360;
                                    setSpinDegrees(currentDegrees);
                                    remainingDegrees -= remainingDegrees / 20;
                                }
                            }, interval);
                        } else if (winner) {
                            // Handle the stopping logic when a winner is determined
                            const chosenSegment = getSegmentByName(
                                winner.title
                            );

                            const segmentIndex = segments.findIndex(
                                (segment: SegmentData) =>
                                    segment.title === chosenSegment.title
                            );
                            let currentDegrees = spinDegrees;
                            const degreesPerSegment = 360 / segments.length;
                            // add offset for randomness
                            const randomOffset =
                                Math.random() * degreesPerSegment;
                            const stopDegrees =
                                360 - segmentIndex * degreesPerSegment; // Ensuring at least 4 full rotations

                            clearInterval(intervalRef.current!);
                            let remainingDegrees = stopDegrees - currentDegrees;
                            if (remainingDegrees < 0) remainingDegrees += 360;
                            slowDownIntervalRef.current = setInterval(() => {
                                if (Math.round(remainingDegrees) <= 0) {
                                    setWinningSegment(segmentIndex);
                                    setTimeout(() => {
                                        if (audioRef.current) {
                                            audioRef.current.pause();
                                            audioRef.current.currentTime = 0;
                                        }
                                    }, 3300);
                                    clearInterval(slowDownIntervalRef.current!);
                                    // toast(`You Won ${chosenSegment.title}`);
                                    // Stop spinner sound
                                    handlePrize();
                                } else {
                                    currentDegrees =
                                        (currentDegrees +
                                            remainingDegrees / 20) %
                                        360;
                                    setSpinDegrees(currentDegrees);
                                    remainingDegrees -= remainingDegrees / 20;
                                }
                            }, interval);
                        }
                    } else {
                        // Continue spinning normally
                        currentDegrees = (currentDegrees + 10) % 360;
                        setSpinDegrees(currentDegrees);
                    }
                }, interval);

                // Play spinner sound
                if (audioRef.current) {
                    audioRef.current.play();
                }
            }

            return () => {
                clearInterval(intervalRef.current!);
                clearInterval(slowDownIntervalRef.current!);
            };
        }, [spinning, winner, spinError]);

        const spinWheel = async () => {
            if (segmentsLoading || !segments || intervalRef.current) return;
            spinAPI();
        };

        useEffect(() => {
            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
                if (slowDownIntervalRef.current) {
                    clearInterval(slowDownIntervalRef.current);
                }
            };
        }, []);

        const getTextColor = useCallback(
            (index: number): string => {
                if (index === winningSegment) {
                    return "#FFF";
                }

                return index % 2 === 0 ? "#000" : "#F4C92D";
            },
            [winningSegment]
        );

        return (
            <>
                <div className="spinner-code text-center">
                    <audio
                        ref={audioRef}
                        src="../../assets/wheelprize5.wav"
                        preload="auto"
                    />
                    {segmentsLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className="spinner-body">
                                <div className="spinner-main-div">
                                    <div className="wheel-section position-relative">
                                        <div
                                            className="spin-container"
                                            style={{
                                                transform: `rotate(${spinDegrees}deg)`,
                                            }}
                                        >
                                            {segments?.map((segment, index) => (
                                                <Segment
                                                    key={index}
                                                    name={segment.title.toUpperCase()}
                                                    backgroundColor={
                                                        index % 2 === 0
                                                            ? "#F3C92D"
                                                            : "#000"
                                                    }
                                                    rotate={
                                                        index *
                                                        (360 / segments!.length)
                                                    }
                                                    textColor={getTextColor(
                                                        index
                                                    )}
                                                />
                                            ))}
                                        </div>
                                        <div className="arrow"></div>
                                        <SpinCircle
                                            onClick={() => {
                                                spinWheel();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Modal
                    show={showModal}
                    centered
                    size="lg"
                    scrollable
                    backdrop="static"
                    onHide={() => setShowModal(false)}
                    className="truck-spin-modal"
                >
                    <ModalBody className="truck-spin-popoup">
                    <div className="container">
                        <h2 className="truck-spin-pop-head">PROFICIAT! JE WINT</h2>
                        <h2 className="truck-pop-winner-name txt-primary">
                            {winner?.title}
                        </h2>
                    </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
);
const WheelWithPrizeAction = withPrize(Wheel);
export default withWheel(WheelWithPrizeAction);
