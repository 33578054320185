import React, { ComponentRef, useImperativeHandle } from "react";
import html2canvas from "html2canvas";
import { WinnerData } from "../../redux/slices/wheelSlice";

export type Downloadable = {
    download: () => void;
};
type Props = {};
export type QrCodeDownloadRef = ComponentRef<typeof QrCodeDownload>;

const QrCodeDownload = React.forwardRef<Downloadable, WinnerData>(
    ({ title, qr_image, prize_expiry }: WinnerData, ref) => {
        useImperativeHandle(
            ref,
            () => {
                return {
                    download() {
                        const input = document.getElementById("pdf-content"); // Specify the id of the element you want to convert to PDF
                        html2canvas(input).then((canvas) => {
                            const imgData = canvas.toDataURL("image/png");
                            const now = new Date();
                            const formattedTime = now
                                .toLocaleDateString("en-IN", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                })
                                .replace(/\//g, "");
                            const link = document.createElement("a");
                            link.href = imgData;
                            link.download = `Jumbo-prize-${formattedTime}.jpg`;
                            link.click();
                        });
                    },
                };
            },
            []
        );

        return (
            <section className="product-section qr-template" id="pdf-content">
                <div className="qr-padding">
                    <div className="inner-width">
                        <div className="qr-second-tile-div">
                            <h3 className="qr-sc-tile">{title}</h3>
                            <p className="expiry-date">
                                GELDIG TOT {prize_expiry}
                            </p>
                        </div>
                        <div className="qr-code-img-sec mb-4">
                            <img
                                src={qr_image}
                                // src="../front/assets/img/demo-qr-img.png"
                                alt=""
                                className="img-fluid qr-img"
                            />
                        </div>
                        <div className="list-foodmarkt">
                            <ul className="food-mr-ul text-center p-2">
                                <li className="li-fd-mr">
                                    1. Ga naar de Foodmarkt Gent
                                </li>
                                <li className="li-fd-mr">
                                    2. wissel je code in aan de ingang
                                </li>
                                <li className="li-fd-mr">
                                    3. geniet je "Spin" Deal
                                </li>
                                <li className="li-fd-mr text-white">
                                    4. en spin opnieuw!
                                </li>
                            </ul>
                        </div>
                        <div className="bottom-img-div">
                            <img
                                src="../front/assets/img/buttom-crop.png"
                                alt=""
                                className="img-fluid btm-img"
                            />
                            <p className="buttom-text-tmp">
                                SITE DECATHLON & WEBA
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
);

export default QrCodeDownload;
