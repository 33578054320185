import React, { useRef } from "react";
import Wheel, { WheelRef } from "../components/Wheel/Wheel";
import { QrCodeDownloadRef } from "../components/QrCodeTemplate/QrCodeDownload";
import { UserState } from "../redux/slices/userSlice";
import { withItems } from "../hoc/withItems";

const SpinnerPage = ({ user }: UserState) => {
    const wheelRef = useRef<WheelRef | null>(null);
    const spinWheel = () => {
        wheelRef?.current?.spinClick();
    };
    return (
        <section className="home-page-section spinner-page">
            <div className="inner-section spinPage-inner-section">
                <div className="">
                    <div className="spin-head-section desktop-view-hide">
                        <div className="email-head-sec position-relative">
                            {user && user?.is_registered ? (
                                <h2 className="email-title text-black text-center">
                                    KLAAR VOOR EEN NIEUWE WIN?
                                </h2>
                            ) : (
                                <>
                                    <h2 className="email-title text-black">
                                        KLAAR VOOR JE EERSTE WIN?
                                    </h2>
                                    <p className="comment-txt eml-txt">
                                        VRIE WAAS
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="spinner-section">
                        <div className="inner-spinner-section">
                            {/*  ==============spinner================================  */}

                            <Wheel ref={wheelRef} />

                            <div className="popup">
                                <div className="popup__container">
                                    <div className="popup__emotion">
                                        <i className="fas fa-meh"></i>
                                    </div>
                                    <p className="popup__note"></p>
                                </div>
                            </div>
                            {/* <!-- ================================================== --> */}
                        </div>
                        <div className="spin-btn-txt margin-top">
                            <div className="email-head-sec position-relative mobile-view-hide">
                                {user && user?.is_registered ? (
                                    <h2 className="email-title text-black">
                                        KLAAR VOOR EEN NIEUWE WIN?
                                    </h2>
                                ) : (
                                    <>
                                        <h2 className="email-title text-black">
                                            KLAAR VOOR JE EERSTE WIN?
                                        </h2>
                                        <p className="comment-txt eml-txt">
                                            VRIE WAAS
                                        </p>
                                    </>
                                )}
                            </div>
                            <p className="spin-win" onClick={spinWheel}>
                                DRAAI &{" "}
                                <span className="win bg-black">WIN!</span>
                            </p>
                        </div>
                    </div>
                    <div className="bottom-text-sec jumbo-txt-butm-img">
                        <div className="head-div d-flex justify-content-center align-items-center">
                            <div className="butm-jumb-img">
                                <img
                                    className="img-fluid btm-logo-img"
                                    src="../front/assets/img/Group-487.svg"
                                    alt=""
                                />
                            </div>
                            <div className="butm-jumb-img food-markt-img">
                                <img
                                    className="img-fluid btm-img-food"
                                    src="../front/assets/img/Group-485.svg"
                                    alt=""
                                />
                                <div className="buttom-new-tab">
                                    <a
                                        href="../assets/Wedstrijdreglement_JUMBO_Draai_Win_Foodmarkt_Gent.pdf"
                                        className="new-page-tab text-black"
                                        target="_blank"
                                    >
                                        <img
                                            src="../front/assets/img/white-i.png"
                                            alt=""
                                            className="modal-i-circle img-fluid"
                                        />
                                    </a>
                                </div>
                            </div>
                            {/* <!-- <h1 className="heading head-size">JUMBO</h1>
              <p className="food-txt bg-black text-white txt-size">
                food<span>markt</span>
              </p> --> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withItems(SpinnerPage);
