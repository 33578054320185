import React, { useCallback, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_LANDING } from "../helpers/Constants";
import { withMyPrize } from "../hoc/withMyPrize";
import { PrizeState } from "../redux/slices/prizeSlice";
import QrCodeDownload, {
    QrCodeDownloadRef,
} from "../components/QrCodeTemplate/QrCodeDownload";
import { Modal, ModalBody } from "react-bootstrap";

const SpinDiscountJustWonPage = ({ prize }: PrizeState) => {
    const { state } = useLocation();
    const downloadRef = useRef<QrCodeDownloadRef | null>(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const handleDownloadQRCode = useCallback(() => {
        downloadRef?.current?.download();
    }, [prize]);

    const handleClosePopup = () => {
        setShowSuccessPopup(false);
    };

    return (
        <section className="product-section home-page-section just-won-page">
            <div className="inner-section">
                <div className="middel-section m-0">
                    <div className="spin-img-div mobile-view-hide">
                        <img
                            src="../front/assets/img/spinner-img.png"
                            alt=""
                            className="spin-img img-fluid"
                        />
                    </div>
                    <div className="inner-width just-won-inner">
                        <div className="qr-title-div">
                            <h2 className="qr-title">PROFICIAT! JE WINT</h2>
                        </div>
                        <div className="qr-second-tile-div position-relative">
                            <h3 className="qr-sc-tile">{state.title}</h3>
                            <p className="expiry-date">
                                GELDIG TOT {prize?.prize_expiry}
                            </p>
                            <div className="new-tab-div">
                                <a
                                    href="#"
                                    className="new-page-tab text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowSuccessPopup(true);
                                    }}
                                >
                                    <img
                                        src="../front/assets/img/white-i.png"
                                        alt=""
                                        className="modal-i-circle img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="qr-code-img-sec mb-4 qr-width">
                            <img
                                src={state.qr_image}
                                alt=""
                                className="img-fluid qr-img"
                            />
                            <div>
                                <div
                                    className="qr-download-div"
                                    onClick={handleDownloadQRCode}
                                ></div>
                                {prize && (
                                    <QrCodeDownload
                                        ref={downloadRef}
                                        title={prize.title}
                                        qr_image={prize.qr_image}
                                        prize_expiry={prize.prize_expiry}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="list-foodmarkt">
                            <ul className="food-mr-ul text-center p-0">
                                <li className="li-fd-mr">
                                    1. Ga naar de Foodmarkt Gent
                                </li>
                                <li className="li-fd-mr">
                                    2. wissel je code in aan de ingang
                                </li>
                                <li className="li-fd-mr">
                                    3. geniet je "Spin" Deal
                                </li>
                                <li className="li-fd-mr text-white">
                                    4. en spin opnieuw!
                                </li>
                            </ul>
                        </div>
                        <div className="verder-btn-div">
                            <Link to={ROUTE_LANDING} className="verder-btn">
                                VERDER
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="bottom-text-sec just-wont-btm">
                    <div className="head-div d-flex justify-content-center align-items-center">
                        <div className="butm-jumb-img">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-487.svg"
                                alt=""
                            />
                        </div>
                        <div className="butm-jumb-img food-markt-img">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-485.svg"
                                alt=""
                            />
                        </div>
                        {/* <h1 className="heading head-size">JUMBO</h1>
            <p className="food-txt bg-black text-white txt-size">
              food<span>markt</span>
            </p>  */}
                    </div>
                </div>
            </div>
            <Modal
                show={showSuccessPopup}
                centered
                size="lg"
                scrollable
                backdrop="static"
                className="road-reg-pop"
            >
                <ModalBody className="reg-popup-body">
                    <div className="container">
                        <h2 className="full-reg-pop-head">
                            {prize?.mail_description} 
                        </h2>
                        <button
                            className="slut-reg-pop"
                            onClick={() => {
                                handleClosePopup();
                            }}
                        >
                            SLUITEN
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </section>
    );
};

export default withMyPrize(SpinDiscountJustWonPage);
