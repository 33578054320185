import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_LANDING } from "../helpers/Constants";
import { withSundayOffers } from "../hoc/withSundayOffers";
import { SundayOfferState } from "../redux/slices/sundayOfferSlice";
import { Spinner } from "../components/Spinner/Spinner";

const SundayOfferPage = ({
    error,
    offers,
    offersLoading,
}: SundayOfferState) => {
    return (
        <section className="product-section home-page-section">
            <div className="top-sec-div bg-none">
                <Link to={ROUTE_LANDING}>
                    <img
                        src="../front/assets/img/black-back.png"
                        alt=""
                        className="black-back-img"
                    />
                </Link>
                <div className="title-div">
                    <img
                        src="../front/assets/img/zotte.png"
                        alt=""
                        className="title-zotte img-fluid"
                    />
                </div>
            </div>
            <div className="inner-section in-height">
                <div className="container">
                    <div className="product-img-grid-sec text-center row">
                        {/* {data.map(item => (
                            <li>
                            </li>
                        )
                        )} */}

                        {offersLoading ? (
                            <Spinner />
                        ) : (
                            offers?.map((item) => (
                                <div className="col-div col-lg-6 col-md-6 col-12" key={item?.id}>
                                    <div className="product-img-div ">
                                        <img
                                            src={item.link}
                                            alt=""
                                            className="pro-img img-fluid"
                                        />
                                    </div>
                                </div>
                            ))
                        )}

                        {/* <div className="product-img-div">
                            <img
                                src="../front/assets/img/product-img1.png"
                                alt=""
                                className="pro-img img-fluid"
                            />
                        </div>
                        <div className="product-img-div">
                            <img
                                src="../front/assets/img/product-img2.png"
                                alt=""
                                className="pro-img img-fluid"
                            />
                        </div>
                        <div className="product-img-div">
                            <img
                                src="../front/assets/img/product-img3.png"
                                alt=""
                                className="pro-img img-fluid"
                            />
                        </div>
                        <div className="product-img-div">
                            <img
                                src="../front/assets/img/product-img1.png"
                                alt=""
                                className="pro-img img-fluid"
                            />
                        </div> */}
                    </div>
                </div>
                {/* <div className="bottom-text-sec jumbo-txt-butm-img">
                    <div className="head-div d-flex justify-content-center align-items-center">
                        <div className="butm-jumb-img">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-487.svg"
                                alt=""
                            />
                        </div>
                        <div className="butm-jumb-img food-markt-img">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-485.svg"
                                alt=""
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    );
};

export default withSundayOffers(SundayOfferPage);
