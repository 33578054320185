import { KeyValueContainer, Registration } from "@snayvik-tech-labs/guestcamp-api";

export class LocalStorage {
    static getUser(): Registration | undefined {
        const  jsonString =  localStorage.getItem("user");
        if(jsonString){
            const registration = JSON.parse(jsonString) as Registration;
            return registration;
        }
        return undefined;
    }

    static setUser(data: KeyValueContainer){
        const _data = {...data}
        const json = JSON.stringify(_data);
        localStorage.setItem("user", json);
    }

    static setVerified(status: Boolean){
        localStorage.setItem('userVarification', JSON.stringify(status))
    }

    static isVerified() {
        const status =  localStorage.getItem('userVarification')
        return status && JSON.parse(status);
    }
}
