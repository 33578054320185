import React, { useState, useEffect } from "react";
import moment from "moment";

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = moment();
        const target = moment(targetDate);
        const difference = target.diff(now);

        if (difference <= 0)
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
        );

        return {
            days,
            hours,
            minutes,
            //  seconds
        };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const updateTimer = () => {
            setTimeLeft(calculateTimeLeft());
        };

        const intervalId = setInterval(updateTimer, 1000);

        return () => clearInterval(intervalId);
    }, [targetDate]);

    return (
        <div>
            <div className="timer-txt">
                <span className="day-name">
                    <span className="text-white time-num">{timeLeft.days}</span>{" "}
                    dagen{" "}
                </span>
                <span className="day-name">
                    <span className="text-white time-num">
                        {timeLeft.hours}
                    </span>{" "}
                    uren{" "}
                </span>
                <span className="day-name">
                    <span className="text-white time-num">
                        {timeLeft.minutes}
                    </span>{" "}
                    minuten{" "}
                </span>
                {/* <span>{timeLeft.seconds} Seconds</span> */}
            </div>
        </div>
    );
};

export default React.memo(Countdown);
