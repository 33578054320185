import React from "react";
import loadingLogo from "../../../../public/front/assets/img/loading.gif";

export default function LoadingSpin() {
    return (
        <span className="loading rotate-360">
            {/* <svg
                id="Icon_feather-settings"
                data-name="Icon feather-settings"
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
            >
                <path
                    id="Path_431"
                    data-name="Path 431"
                    d="M22.5,18A4.5,4.5,0,1,1,18,13.5,4.5,4.5,0,0,1,22.5,18Z"
                    fill="none"
                    stroke="#eda009"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                />
                <path
                    id="Path_432"
                    data-name="Path 432"
                    d="M29.1,22.5a2.475,2.475,0,0,0,.495,2.73l.09.09a3,3,0,1,1-4.245,4.245l-.09-.09a2.5,2.5,0,0,0-4.23,1.77V31.5a3,3,0,1,1-6,0v-.135A2.475,2.475,0,0,0,13.5,29.1a2.475,2.475,0,0,0-2.73.495l-.09.09A3,3,0,1,1,6.435,25.44l.09-.09a2.5,2.5,0,0,0-1.77-4.23H4.5a3,3,0,1,1,0-6h.135A2.475,2.475,0,0,0,6.9,13.5a2.475,2.475,0,0,0-.5-2.73l-.09-.09A3,3,0,1,1,10.56,6.435l.09.09a2.475,2.475,0,0,0,2.73.495h.12A2.475,2.475,0,0,0,15,4.755V4.5a3,3,0,0,1,6,0v.135a2.5,2.5,0,0,0,4.23,1.77l.09-.09a3,3,0,1,1,4.245,4.245l-.09.09a2.475,2.475,0,0,0-.5,2.73v.12A2.475,2.475,0,0,0,31.245,15H31.5a3,3,0,0,1,0,6h-.135A2.475,2.475,0,0,0,29.1,22.5Z"
                    fill="none"
                    stroke="#eda009"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                />
            </svg> */}
            <img
                className="img-fluid full-loading"
                src={loadingLogo}
                alt="loading..."
            />
        </span>
    );
}
