import React from "react";
import Dropshadow from "../../..//img/dropshadow.png";
interface SpinButtonProps {
    onClick?: () => void
}

const SpinCircle: React.FC<SpinButtonProps> = ({onClick}) => {
    return (
        <div className="spin-rotate-btn" style={{cursor: "pointer"}} onClick={onClick}>
            <img className="spin-btn-img" src={Dropshadow} alt="" />
        </div>
    );
};

export default SpinCircle;
