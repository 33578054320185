export const API_URL = import.meta.env.VITE_API_URL;
export const GUESTCAMP_APP_BASE_URL = import.meta.env.VITE_GUESTCAMP_APP_BASE_URL;
export const GUESTCAMP_APP_API_KEY = import.meta.env.VITE_GUESTCAMP_APP_API_KEY;
export const GUESTCAMP_APP_CLIENT_NAME = import.meta.env.VITE_GUESTCAMP_APP_CLIENT_NAME;
export const GUESTCAMP_APP_EVENT_NAME = import.meta.env.VITE_GUESTCAMP_APP_EVENT_NAME;

// item title constants...
export const ITEM_TITLE_SPIN_N_WIN = "SPIN&WIN";
export const ITEM_TITLE_WEEK_DEAL = "FAN_DEAL";
export const ITEM_TITLE_FOLDER = "FOLDER";
export const ITEM_TITLE_JUMBO = "JUMBO";
export const ITEM_TITLE_MOBILE_PRACTICE = "Jumbo Gent";
export const ITEM_TITLE_FOODMARKT_OPENING = "FOOD_MART OPENING";


// item type constants...
export const ITEM_TYPE_SPIN_N_WIN = "spin_wheel";
export const ITEM_TYPE_WEEK_DEAL = "fan_deal";
export const ITEM_TYPE_FOLDER = "folder";
export const ITEM_TYPE_JUMBO = "crazy_sunday";
export const ITEM_TYPE_MOBILE_PRACTICE = "praktische_informate";
export const ITEM_TYPE_FOODMARKT_OPENING = "mart_opening";


// Route Constants...
export const ROUTE_ROOT_PAGE = "/";
export const ROUTE_REGISTRATION = "/registration";
export const ROUTE_FULL_REGISTRATION = "/full-registration";
export const ROUTE_SPIN_N_WIN = "/spinner";
export const ROUTE_LANDING = "/landing";
export const ROUTE_MOBILE_PRACTICE = "/mobile-practice";
export const ROUTE_FOLDER = "/folder";
export const ROUTE_SUNDAY_OFFER = "/sunday-offer";
export const ROUTE_SPIN_DISCOUNT_JUST_WON = "/spin-discount-just-won";
export const ROUTE_SPIN_DISCOUNT = "/spin-discount";
export const ROUTE_WEEK_DEAL = "/week-deal";
export const ROUTE_OTP_VERIFICATION = "/verification";

export const USER_CAN_SPIN = "can_spin";
export const USER_CAN_SPIN_TODAY = "can_spin_today";
export const USER_CAN_REDEEM_CODE = "can_redeem_code";
export const USER_SPIN_DISABLE = "spin_disable";
