import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { MainApp } from "./components/MainApp";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const rootElement = document.getElementById("app");
const root = createRoot(rootElement);

root.render(
    // <App />
    <React.StrictMode>
        {/* <MainApp /> */}
        <Provider store={store}>
            <ToastContainer />
            <App />
        </Provider>
    </React.StrictMode>
);
