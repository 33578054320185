import React, {useEffect} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../helpers/Constants";
import {SegmentData} from "../components/Wheel/Wheel";
import {APIResponse} from "../redux/models/APIResponse";
import {WinnerData} from "../redux/slices/wheelSlice";
import {LocalStorage} from "../helpers/LocalStorage";
import {fetchPrize, PrizeState} from "../redux/slices/prizeSlice";
import {PrizeAction} from "./withPize";
import {fetchSundayOffers, SundayOfferState} from "../redux/slices/sundayOfferSlice";

export interface OffersAction {
}
export function withSundayOffers<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = (props: P & SundayOfferState & OffersAction) => {
        const dispatch = useAppDispatch();
        const sundayOfferState = useSelector((state: RootState) => state.sundayOffers);

        useEffect(() => {
            const user = LocalStorage.getUser()!;
            if (sundayOfferState.offersLoading === false && sundayOfferState.offers === undefined){
                dispatch(fetchSundayOffers(user))
            }
        }, [])

        return <WrappedComponent {...props}
            {...sundayOfferState}
        />
    }
    return UserControlled;
}
