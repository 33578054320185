import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTE_LANDING } from "../helpers/Constants";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useWindowSize } from "@uidotdev/usehooks";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FolderPage = () => {
    const margin = 15;
    const pageStyle = {
        margin: `${margin}px`, // Set your desired margin here
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // Optional: add shadow for better visual separation
    };
    const pageInfoStyle = {
        position: "fixed",
        top: "100px",
        left: `${margin + 10}px`,
        padding: "10px",
        backgroundColor: "#ECB719",
        color: "rgb(135 113 49)",
        borderRadius: "5px",
    };

    const size = useWindowSize();
    const [data, setData] = useState([]);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const containerRef = useRef(null);
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        console.info({ numPages });
        setNumPages(numPages);
    }

    useEffect(() => {
        fetch("/api/fetch-folder", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => {
                return setData(response.data);
            });
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            // @ts-ignore
            const pageElements = container.querySelectorAll(
                ".react-pdf__Document > div"
            );
            let currentPage = 1;

            // pageElements.forEach((pageElement: { getBoundingClientRect: () => { top: number; bottom: number; }; }, index: number) => {
            //     const { top, bottom } = pageElement.getBoundingClientRect();
            //
            //     // @ts-ignore
            //     const containerTop = container.getBoundingClientRect().top;
            //
            //     if (top < ((containerTop + window.innerHeight) / 2) && bottom > containerTop) {
            //         currentPage = index + 1;
            //     }
            //     console.info({ top, bottom, containerTop, currentPage })
            // });
            // setPageNumber(currentPage);
            let maxVisibleArea = 0;
            pageElements.forEach((pageElement, index) => {
                const { top, bottom, height } =
                    pageElement.getBoundingClientRect();
                const containerHeight = window.innerHeight;

                // Calculate how much of the page is visible in the viewport
                const visibleTop = Math.max(top, 0);
                const visibleBottom = Math.min(bottom, containerHeight);
                const visibleHeight = visibleBottom - visibleTop;

                const visibleArea = Math.max(0, visibleHeight / height);

                if (visibleArea > maxVisibleArea) {
                    maxVisibleArea = visibleArea;
                    currentPage = index + 1;
                }
                console.info({ top, bottom, currentPage });
            });
            setPageNumber(currentPage);
        };

        const container = containerRef.current;
        // @ts-ignore
        // container.addEventListener('scroll', handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            // @ts-ignore
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <section className="dit-main-section">
            <div className="top-sec-div folder-top">
                <Link to={ROUTE_LANDING}>
                    <img
                        src="../front/assets/img/black-back.png"
                        alt=""
                        className="black-back-img"
                    />
                </Link>
            </div>
            <div ref={containerRef}>
                {data && (
                    <Document
                        file={data.link}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {[...Array(numPages).keys()].map((_, index) => {
                            return (
                                <div
                                    key={`page_${index + 1}`}
                                    style={pageStyle}
                                >
                                    <Page
                                        key={`pdf_page_${index + 1}`}
                                        renderTextLayer={false}
                                        pageNumber={index + 1}
                                        width={(size.width ?? 300) - margin * 2}
                                    />
                                </div>
                            );
                        })}
                    </Document>
                )}
            </div>
            {numPages && (
                <div style={pageInfoStyle}>
                    <p>
                        Page {pageNumber} of {numPages}
                    </p>
                </div>
            )}
        </section>
    );
};

export default FolderPage;
