// import React from "react";
// import { Outlet } from "react-router-dom";
// import { UserState } from "../redux/slices/userSlice";
// import { withUser } from "../hoc/withUser";
// import LoadingSpin from "../components/icons/LoadingSpin";
// import useScreenOrientation from "../hooks/getOrientation.js";

// function Layout(props: { userState: UserState }) {
//     const orientation = useScreenOrientation();
//     console.info({ orientation });
//     const isLandscape = orientation === "landscape-primary";

//     return (
//         <div className="main-wrapper">
//             <div
//                 className={`main-wrapper ${isLandscape ? "rotate-layout" : ""}`}
//             >
//                 {/* <LoadingSpin /> */}
//                 {props.userState.userLoading ? (
//                     <LoadingSpin />
//                 ) : (
//                     <Outlet context={{ ...props }} />
//                 )}
//             </div>
//         </div>
//     );
// }

// export default withUser(Layout);

import React from "react";
import { Outlet } from "react-router-dom";
import { UserState } from "../redux/slices/userSlice";
import { withUser } from "../hoc/withUser";
import LoadingSpin from "../components/icons/LoadingSpin";
import useScreenOrientation from "../hooks/getOrientation.js";

interface LayoutProps {
    userState: UserState;
}

function Layout(props: LayoutProps) {
    const orientation = useScreenOrientation();
    // console.info({ orientation });

    const isLandscape =
        orientation === "landscape-primary" ||
        orientation === "landscape-secondary";

    return (
        <div className={`main-wrapper ${isLandscape ? "rotate-layout" : ""}`}>
            {props.userState.userLoading ? (
                <LoadingSpin />
            ) : (
                <Outlet context={{ ...props }} />
            )}
        </div>
    );
}

export default withUser(Layout);
