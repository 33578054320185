import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";


export interface WheelState {
    segments?: Array<SegmentData> | null,
    segmentsLoading: boolean,

    winner?: WinnerData | null,
    spinning: boolean,
    spinError: null,

    error: null,
}

export interface SegmentData {
    title: string;
    substitle: string;
}

const initialState: WheelState = {
    segments: undefined,
    segmentsLoading: false,
    error: null,

    winner: undefined,
    spinning: false,
    spinError: null
};


export interface WinnerData {
    title?: string
    qr_image?: string
    prize_id?: number
    can_spin_today?: boolean
}
type SegementsResponseType = APIResponse<Array<SegmentData>>
export const fetchSegments = createAsyncThunk<SegementsResponseType, User, { rejectValue: Error }>('segments', async (args) => {
    const response = await axios.get<User>(`${API_URL}segments?mail_recepient_id=${args.mail_recepient_id}`);
    return response.data;
});

type WinnerResponseType = APIResponse<WinnerData>
export const spinWheel = createAsyncThunk<WinnerResponseType, User, { rejectValue: Error }>('spinwheel', async (args) => {
        const response = await axios.get<User>(`${API_URL}spin?mail_recepient_id=${args.mail_recepient_id}`);
        return response.data;
});

const wheelSlice = createSlice<WheelState>({
    name: 'wheel',
    initialState,
    reducers: {
        nop: (state) => state,
        resetWheelState: (state: WheelState) => {
            state.segments =  initialState.segments
            state.segmentsLoading = initialState.segmentsLoading
            state.error = initialState.error
            state.winner = initialState.winner
            state.spinning = initialState.spinning
            state.spinError = initialState.spinError
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSegments.pending, (state) => {
                state.segmentsLoading = true;
                state.error = null;
            })
            .addCase(fetchSegments.fulfilled, (state, action: PayloadAction<SegementsResponseType>) => {
                state.segmentsLoading = false;
                state.segments = action.payload.data;
            })
            .addCase(fetchSegments.rejected, (state, action) => {
                state.segmentsLoading = false;
                state.segments = null;
                state.error = action.error?.message || 'Failed to fetch segments';
            })

            .addCase(spinWheel.pending, (state) => {
                state.spinning = true;
                state.error = null;
            })
            .addCase(spinWheel.fulfilled, (state, action: PayloadAction<WinnerResponseType>) => {
                state.spinning = false;
                state.winner = action.payload.data;
            })
            .addCase(spinWheel.rejected, (state, action) => {
                state.spinning = false;
                state.winner = null;
                state.spinError = action.error?.message || 'Failed to fetch segments';
            })
        ;
    },
});

export const { resetWheelState } = wheelSlice.actions;
export default wheelSlice.reducer;
