import React, {useEffect} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import {fetchUser, UserState} from "../redux/slices/userSlice";
import {checkEmail, EmailCheckState} from "../redux/slices/emailSlice";
import {GuestCampClient, MailExist} from "@snayvik-tech-labs/guestcamp-api";
import {User} from "../redux/models/User";
import {LocalStorage} from "../helpers/LocalStorage";
import {useNavigate, useLocation} from "react-router-dom";
import {
    ROUTE_FULL_REGISTRATION,
    ROUTE_LANDING, ROUTE_OTP_VERIFICATION,
    ROUTE_REGISTRATION,
    ROUTE_ROOT_PAGE, ROUTE_SPIN_DISCOUNT_JUST_WON, ROUTE_SPIN_N_WIN, ROUTE_WEEK_DEAL
} from "../helpers/Constants";
import {resetWheelState} from "../redux/slices/wheelSlice";

export interface UserStateModel {
    checkEmail: (User) => void,
    mailCheckState: EmailCheckState,
    userState: UserState
}
export function withUser<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = (props: P & UserStateModel) => {
        const navigate = useNavigate();
        const { hash, pathname, search, state } = useLocation()
        const dispatch = useAppDispatch();
        const userState = useSelector((state: RootState) => state.user);
        const wheelState = useSelector((state: RootState) => state.wheel);
        const mailCheckState = useSelector((state: RootState) => state.mailCheck);

        const checkEmailFun = (object: {email: string}) => {
            if (mailCheckState.emailCheckLoading === false){
                dispatch(checkEmail(object));
            }
        }

        useEffect(() => {
            const registration = LocalStorage.getUser()
            if (registration && userState.userLoading === false) {
                dispatch(fetchUser(registration));
            }
        }, [dispatch]);

        useEffect(() => {
           const user =  LocalStorage.getUser();
            if (user === undefined){
                if (!(pathname === ROUTE_FULL_REGISTRATION || pathname === ROUTE_REGISTRATION)){
                    navigate(ROUTE_ROOT_PAGE)
                }
            }else if (userState.user) {
                if ( wheelState.winner){
                    return;
                }else if (userState.user.can_spin_today && !LocalStorage.isVerified()){
                    navigate(ROUTE_SPIN_N_WIN)
                }else {
                    if (LocalStorage.isVerified()){
                        dispatch(resetWheelState({}));
                        navigate(ROUTE_LANDING)
                    }else {
                        navigate(ROUTE_OTP_VERIFICATION, {state: {route_to_landing: true}})
                    }

                }
            }
        }, [userState.user])

        return <WrappedComponent {...props}
                                 userState={userState}
                                 mailCheckState={mailCheckState}
                                 checkEmail={checkEmailFun}
        />
    }
    return UserControlled;
}
