// import { useState, useEffect } from 'react'

// const getOrientation = () =>
//     window.screen.orientation.type

// const useScreenOrientation = () => {
//     const [orientation, setOrientation] =
//         useState(getOrientation())

//     const updateOrientation = event => {
//         setOrientation(getOrientation())
//     }

//     useEffect(() => {
//         window.addEventListener(
//             'orientationchange',
//             updateOrientation
//         )
//         return () => {
//             window.removeEventListener(
//                 'orientationchange',
//                 updateOrientation
//             )
//         }
//     }, [])

//     return orientation
// }

// export default useScreenOrientation



import { useState, useEffect } from 'react';

const getOrientation = () => {
    if (window.screen && window.screen.orientation && window.screen.orientation.type) {
        return window.screen.orientation.type;
    } else {
        // Fallback for browsers that don't support window.screen.orientation
        const angle = window.orientation;
        if (angle === 0 || angle === 180) {
            return 'portrait-primary'; // or 'portrait-secondary'
        } else if (angle === 90 || angle === -90) {
            return 'landscape-primary'; // or 'landscape-secondary'
        } else {
            return 'unknown';
        }
    }
};

const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    useEffect(() => {
        if (window.screen && window.screen.orientation) {
            window.screen.orientation.addEventListener('change', updateOrientation);
        } else {
            window.addEventListener('orientationchange', updateOrientation);
        }

        return () => {
            if (window.screen && window.screen.orientation) {
                window.screen.orientation.removeEventListener('change', updateOrientation);
            } else {
                window.removeEventListener('orientationchange', updateOrientation);
            }
        };
    }, []);

    return orientation;
};

export default useScreenOrientation;
