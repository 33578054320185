import React, { useCallback, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTE_LANDING } from "../helpers/Constants";
import { withMyPrize } from "../hoc/withMyPrize";
import { PrizeState } from "../redux/slices/prizeSlice";

import QrCodeDownload, {
    QrCodeDownloadRef,
} from "../components/QrCodeTemplate/QrCodeDownload";
import { Modal, ModalBody } from "react-bootstrap";

const SpinDiscountPage = ({ prize }: PrizeState) => {
    const downloadRef = useRef<QrCodeDownloadRef | null>(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const handleDownloadQRCode = useCallback(() => {
        downloadRef?.current?.download();
    }, [prize]);
    const handleClosePopup = () => {
        setShowSuccessPopup(false);
    };

    return (
        <section className="product-section home-page-section position-relative">
            <div className="inner-section over-hide">
                <div className="back-div">
                    <Link to={ROUTE_LANDING}>
                        <img
                            src="../front/assets/img/black-back.png"
                            alt=""
                            className="black-back-img"
                        />
                    </Link>
                </div>
                <div className="middel-section">
                    <div className="spin-img-div mobile-view-hide">
                        <img
                            src="../front/assets/img/spinner-img.png"
                            alt=""
                            className="spin-img img-fluid"
                        />
                    </div>
                    <div className="inner-width spin-width">
                        <div className="qr-title-div">
                            <h2 className="qr-title">
                                WISSEL JE CODE IN BIJ{" "}
                                <span className="text-white">
                                    FOODMARKT GENT
                                </span>
                            </h2>
                            <p className="comment-txt eml-txt">VRIE WAAS</p>
                        </div>

                        <div className="qr-code-img-sec">
                            {prize?.image && (
                                <div className="qr-img-div">
                                    <img
                                        src={prize.qr_image}
                                        // src="../front/assets/img/demo-qr-img.png"
                                        alt=""
                                        className="img-fluid qr-img wissel-qr-img"
                                    />
                                </div>
                            )}
                            <div>
                                <div
                                    className="qr-download-div"
                                    onClick={handleDownloadQRCode}
                                ></div>
                                {prize && (
                                    <QrCodeDownload
                                        ref={downloadRef}
                                        title={prize.title}
                                        qr_image={prize.qr_image}
                                        prize_expiry={prize.prize_expiry}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="qr-second-tile-div position-relative">
                            {prize?.image && (
                                <h3 className="qr-sc-tile">{prize.title}</h3>
                            )}
                            <p className="expiry-date">
                                GELDIG TOT {prize?.prize_expiry}
                            </p>
                            <div className="new-tab-div">
                                <a
                                    href="#"
                                    className="new-page-tab text-black"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowSuccessPopup(true);
                                    }}
                                >
                                    <img
                                        src="../front/assets/img/white-i.png"
                                        alt=""
                                        className="modal-i-circle img-fluid"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="list-foodmarkt">
                            <ul className="food-mr-ul text-center p-0">
                                <li className="li-fd-mr">
                                    1. Ga naar de Foodmarkt Gent
                                </li>
                                <li className="li-fd-mr">
                                    2. wissel je code in aan de ingang
                                </li>
                                <li className="li-fd-mr">
                                    3. geniet je "Spin" Deal
                                </li>
                                <li className="li-fd-mr text-white">
                                    4. en spin opnieuw!
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="bottom-text-sec">
                    <div className="head-div d-flex justify-content-center align-items-center">
                        <div className="butm-jumb-img desktop-view-hide ">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-487.svg"
                                alt=""
                            />
                        </div>
                        <div className="butm-jumb-img food-markt-img desktop-view-hide ">
                            <img
                                className="img-fluid"
                                src="../front/assets/img/Group-485.svg"
                                alt=""
                            />
                        </div>
                        <div className="mobile-view-hide text-center">
                            <img
                                src="../front/assets/img/buttom-crop.png"
                                alt=""
                                className="img-fluid spin-dis-btm-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showSuccessPopup}
                centered
                size="lg"
                scrollable
                backdrop="static"
                className="road-reg-pop"
            >
                <ModalBody className="reg-popup-body">
                    <div className="container">
                        <h2 className="full-reg-pop-head">
                            {prize?.mail_description} 
                        </h2>
                        <button
                            className="slut-reg-pop"
                            onClick={() => {
                                handleClosePopup();
                            }}
                        >
                            SLUITEN
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </section>
    );
};

export default withMyPrize(SpinDiscountPage);
