import React, {forwardRef, useCallback, useEffect, useRef, useState} from "react";
import {RootState, useAppDispatch} from "../redux/store/store";
import {useSelector} from "react-redux";
import axios from "axios";
import {API_URL, ROUTE_LANDING, ROUTE_OTP_VERIFICATION, ROUTE_SPIN_DISCOUNT_JUST_WON} from "../helpers/Constants";
import {SegmentData} from "../components/Wheel/Wheel";
import {APIResponse} from "../redux/models/APIResponse";
import {spinWheel, WheelState, WinnerData} from "../redux/slices/wheelSlice";
import {LocalStorage} from "../helpers/LocalStorage";
import {fetchPrize, PrizeState} from "../redux/slices/prizeSlice";
import {fetchUser, updateUser, UserState} from "../redux/slices/userSlice";
import {useNavigate, useNavigation} from "react-router-dom";

export interface PrizeAction {
    spinAPI: () => WinnerData
    handlePrize: () => void
}
export function withPrize<P>(WrappedComponent: React.ComponentType<P>) {
    const UserControlled = forwardRef((props: P & PrizeAction & UserState & WheelState, ref) => {
        const dispatch = useAppDispatch();
        const prizeState = useSelector((state: RootState) => state.prize);
        const wheelState = useSelector((state: RootState) => state.wheel);
        const navigate = useNavigate();
        const [showModal, setShowModal] = useState(false);
        // const navigation = useNavigation();

        const user = LocalStorage.getUser()!;

        const spinAPI = async (): Promise<SegmentData> => {
            // Replace with actual API call
            const user = LocalStorage.getUser()!;
            dispatch(spinWheel(user))
        };

        const handlePrize = useCallback(() => {
            if (wheelState.winner){
                dispatch(updateUser(wheelState.winner))
                    const isValidUser = LocalStorage.isVerified()
                    console.log(isValidUser);
                    if(isValidUser){
                        setTimeout(() => {
                            setShowModal(true); // Show the popup
                            setTimeout(() => {
                                setShowModal(false);
                                navigate(ROUTE_SPIN_DISCOUNT_JUST_WON, {
                                    replace: true,
                                    state: wheelState.winner,
                                });
                            }, 5000);
                        }, 3000);
                    }else{
                        setTimeout(() => {
                            setShowModal(true); // Show the popup
                            setTimeout(() => {
                                setShowModal(false);
                                navigate(ROUTE_OTP_VERIFICATION, {
                                    replace: true,
                                    state: wheelState.winner,
                                });
                            }, 5000);
                        },3300)
                    }
            }else {
                dispatch(updateUser({
                    qr_image: null,
                    prize_id: null,
                    can_spin_today: false
                }))
                setTimeout(() => {
                    navigate(ROUTE_LANDING, {
                        replace: true
                    });
                }, 2000)
            }
        }, [wheelState.winner])



        return <WrappedComponent {...props}
                                ref={ref}
                                {...prizeState}
                                spinAPI={spinAPI}
                                handlePrize={handlePrize}
                                setShowModal={setShowModal}
                                showModal={showModal}
        />
    });

    return UserControlled;
}
