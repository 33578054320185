import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IntroPage from "./pages/IntroPage";
import RegistrationPage from "./pages/RegistrationPage";
import FullRegistrationPage from "./pages/FullRegistrationPage";
import SpinnerPage from "./pages/SpinnerPage";
import LandingPage from "./pages/LandingPage";
import MobilePracticePage from "./pages/MobilePracticalPage";
import FolderPage from "./pages/FolderPage";
import SpinDiscountJustWonPage from "./pages/SpinDiscountJustWonPage";
import WeekDealPage from "./pages/WeekDeal";
import Layout from "./pages/Layout";
import SundayOfferPage from "./pages/SundayOfferPage";
import { GuestCampClient } from "@snayvik-tech-labs/guestcamp-api";
import {
    GUESTCAMP_APP_API_KEY,
    GUESTCAMP_APP_BASE_URL,
    GUESTCAMP_APP_CLIENT_NAME,
    GUESTCAMP_APP_EVENT_NAME,
    ROUTE_FOLDER,
    ROUTE_FULL_REGISTRATION,
    ROUTE_FUN_DEAL,
    ROUTE_LANDING,
    ROUTE_MOBILE_PRACTICE,
    ROUTE_OTP_VERIFICATION,
    ROUTE_REGISTRATION,
    ROUTE_ROOT_PAGE,
    ROUTE_SPIN_DISCOUNT,
    ROUTE_SPIN_DISCOUNT_JUST_WON,
    ROUTE_SPIN_N_WIN,
    ROUTE_SUNDAY_OFFER,
    ROUTE_WEEK_DEAL,
} from "./helpers/Constants";
import SpinDiscountPage from "./pages/SpinDiscountPage";
import FanDealPage from "./pages/FanDealPage";
import OtpVerificationPage from "./pages/OtpVerificationPage";
// import "../css/spinner.css";

GuestCampClient.init({
    base_url: GUESTCAMP_APP_BASE_URL,
    api_token: GUESTCAMP_APP_API_KEY,
    client_name: GUESTCAMP_APP_CLIENT_NAME,
    event: GUESTCAMP_APP_EVENT_NAME,
});

export default function App() {
    
    const isIntro = window.is_intro === true;

    return (
        <BrowserRouter>
            <Routes>
                <Route path={ROUTE_ROOT_PAGE} element={<Layout />}>
                    {/* <Route index element={<IntroPage />} /> */}
                    {isIntro ? (
                        <Route index element={<IntroPage />} />
                    ) : (
                        <Route index element={<RegistrationPage />} />
                    )}
                    <Route
                        path={ROUTE_REGISTRATION}
                        element={<RegistrationPage />}
                    />
                    <Route
                        path={ROUTE_FULL_REGISTRATION}
                        element={<FullRegistrationPage />}
                    />
                    <Route path={ROUTE_SPIN_N_WIN} exact element={<SpinnerPage />} />
                    <Route path={ROUTE_LANDING} element={<LandingPage />} />
                    <Route
                        path={ROUTE_MOBILE_PRACTICE}
                        element={<MobilePracticePage />}
                    />
                    <Route path={ROUTE_FOLDER} element={<FolderPage />} />
                    <Route path={ROUTE_SUNDAY_OFFER} element={<SundayOfferPage />} />
                    <Route
                        path={ROUTE_SPIN_DISCOUNT_JUST_WON}
                        element={<SpinDiscountJustWonPage />}
                    />
                    {/* <Route path={ROUTE_WEEK_DEAL} element={<WeekDealPage />} /> */}
                    <Route path={ROUTE_WEEK_DEAL} element={<FanDealPage />} />
                    {/* <Route path={ROUTE_FUN_DEAL} element={<FanDealPage />} /> */}
                    <Route
                        path={ROUTE_SPIN_DISCOUNT}
                        element={<SpinDiscountPage />}
                    />
                    <Route path={ROUTE_OTP_VERIFICATION} element={<OtpVerificationPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
