import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {User} from "../models/User";
import {API_URL} from "../../helpers/Constants";
import {APIResponse} from "../models/APIResponse";
import {WinnerData} from "./wheelSlice";


export interface UserState {
    user?: User | null | undefined;
    userLoading: boolean;
    error?: Error | null;
}

const initialState: UserState = {
    user: undefined,
    userLoading: false,
    error: null,
};
type WinnerResponseType = APIResponse<User>
export const fetchUser = createAsyncThunk<WinnerResponseType, User, { rejectValue: Error }>('user', async (args) => {
    const response = await axios.get<User>(`${API_URL}fetch-winner?mail_recepient_id=${args.mail_recepient_id}`);
    return response.data;
});

const userSlice = createSlice<UserState>({
    name: 'user',
    initialState,
    reducers: {
        nop: (state) => state,
        updateUser: (state: UserState, actio: PayloadAction<WinnerData>) => {
            if (state.user){
                state.user.prize_id = actio.payload.prize_id
                state.user.qr_image = actio.payload.qr_image
                state.user.can_spin_today = actio.payload.can_spin_today
                state.user.auto_route = false
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.userLoading = true;
                state.error = null;
            })
            .addCase(fetchUser.fulfilled, (state, action: PayloadAction<WinnerResponseType>) => {
                state.userLoading = false;
                state.user = action.payload.data;
                state.user.auto_route = true;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.userLoading = false;
                state.user = null;
                state.error = action.error?.message || 'Failed to fetch users';
            });
    },
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;

